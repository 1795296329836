import httpUpload from "Redux/http/http-upload";
class UploadService {
  uploadFile(data) {
    return httpUpload.post(
      `trillium-claim-uploader/v1/upload/claim/file?clinicId=${data?.clinicId}&emrType=${data.emrType}`,
      data.file
    );
  }
  uploadStatus(clinicId) {
    return httpUpload.get(
      `trillium-claim-uploader/v1/upload/status?clinicId=${clinicId}`
    );
  }
  download(fileName) {
    return httpUpload.get(
      `trillium-claim-uploader/v1/upload/download?filePath=${fileName}`,
      { responseType: "blob" }
    );
  }
}
export default new UploadService();
